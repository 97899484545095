import { Box, Button, Container, Heading, SimpleGrid, Text, Stack } from '@chakra-ui/react';
import { GetServerSideProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import DonationDrive from '../components/DonationDrive';
import { defaultSiteTheme, FrontendLayout } from '../components/FrontendLayout';
import { HomePageData, getHomeData } from '../lib/rails_api/getHomeData';
import frontendTheme from '../styles/frontendTheme';

const homePageTheme = {
  ...defaultSiteTheme,
  styles: {
    ...defaultSiteTheme.styles,
    global: {
      ...defaultSiteTheme.styles.global,
      // styles for the `body`
      body: {
        ...defaultSiteTheme.styles.global.body,
        bg: frontendTheme.lightYellowBackground,
      },
    },
  },
};

type HomePageProps = {
  homeData: HomePageData;
};

const HomePage = ({ homeData }: HomePageProps): JSX.Element => {
  const { banner } = homeData;

  return (
    <FrontendLayout theme={homePageTheme}>
      <Box bg="#FFF3F7">
        <Container maxW="container.md" mx="auto" textAlign="center" pt="8">
          <Heading as="h1" size="3xl" mx="auto" mt="8" color="#F00404">
            Support your independent, non-profit Perth community radio station, RTRFM ♡
          </Heading>

          <DonationDrive homeData={homeData} linkToDonatePage />

          <Box mt="40" pb="20">
            <SimpleGrid columns={2} minChildWidth="300px" spacingX={10} spacingY={40}>
              <Link href={`/subscribe`}>
                <Box
                  minH={'24'}
                  marginTop={-10}
                  bgColor="white"
                  position={'relative'}
                  zIndex={1}
                  boxShadow="lg"
                  textAlign={'left'}
                  paddingX="10"
                  paddingBottom="8"
                  maxWidth={400}
                  marginX="auto"
                >
                  <Box
                    position={'absolute'}
                    bgColor="white"
                    minH={24}
                    top={0}
                    left={0}
                    right={0}
                    zIndex={-1}
                    transform="skewY(-7deg)"
                    transformOrigin="0 0"
                  ></Box>
                  <Stack cursor="pointer" spacing="6">
                    <Heading
                      as="h3"
                      mt="4"
                      size="lg"
                      textTransform="uppercase"
                      letterSpacing="wider"
                      mx="auto"
                      color="#0039CD"
                      fontFamily="body"
                    >
                      Subscribe
                    </Heading>
                    <Text>
                      Support the station for a year. Enjoy benefits like reduced entry prices for
                      RTR gigs and discounts at a range of awesome local Perth businesses.
                    </Text>
                    <Box>
                      <Button variant="solid" colorScheme="pink">
                        Subscribe Now
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Link>
              <Link href={`/subscribe/0`}>
                <Box
                  minH={'24'}
                  marginTop={-10}
                  bgColor="white"
                  position={'relative'}
                  zIndex={1}
                  boxShadow="lg"
                  textAlign={'left'}
                  paddingX="10"
                  paddingBottom="8"
                  maxWidth={400}
                  marginX="auto"
                >
                  <Box
                    position={'absolute'}
                    bgColor="white"
                    minH={24}
                    top={0}
                    left={0}
                    right={0}
                    zIndex={-1}
                    transform="skewY(-7deg)"
                    transformOrigin="0 0"
                  ></Box>
                  <Stack cursor="pointer" spacing="6">
                    <Heading
                      as="h3"
                      mt="4"
                      size="lg"
                      textTransform="uppercase"
                      letterSpacing="wider"
                      mx="auto"
                      color="#0039CD"
                      fontFamily="body"
                    >
                      Donate
                    </Heading>
                    <Text>Make a one-off or recurring tax-deductible donation to RTRFM.</Text>
                    <Box>
                      <Button variant="solid" colorScheme="pink">
                        Donate Now
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Link>
            </SimpleGrid>
          </Box>

          {banner.image_url && (
            <Box pb="14">
              <a href={banner.link}>
                <Image
                  src={`${banner.image_url}`}
                  width={banner.image_dimensions.width}
                  height={banner.image_dimensions.height}
                />
              </a>
            </Box>
          )}
        </Container>
      </Box>
    </FrontendLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  query,
}): Promise<{ props: HomePageProps }> => {
  const homeData = await getHomeData(
    query.preview_donation ? parseInt(query.preview_donation as string) : undefined
  );
  return {
    props: {
      homeData,
    },
  };
};

//const AuthPage = withAuthUser({})(HomePage as ComponentType<unknown>);
export default HomePage;
