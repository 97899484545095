import { Box, SimpleGrid, Button } from '@chakra-ui/react';
import Image from 'next/image';
import Link from 'next/link';

import { HomePageData } from '../lib/rails_api/getHomeData';

type DonationDriveProps = {
  homeData: HomePageData;
  linkToDonatePage: boolean;
};

export default function DonationDrive({
  homeData,
  linkToDonatePage,
}: DonationDriveProps): JSX.Element | null {
  const { donation_drive } = homeData;

  if (!donation_drive) {
    return null;
  }
  return (
    <Box maxW={'container.sm'} mx="auto">
      {donation_drive.image && donation_drive.image_dimensions && (
        <Box maxW="80%" width="80%" marginX="auto" marginTop={'12'}>
          <Image
            layout="responsive"
            src={donation_drive.image}
            width={donation_drive.image_dimensions?.width}
            height={donation_drive.image_dimensions?.height}
          />
        </Box>
      )}

      <Box
        minH={'24'}
        marginTop={donation_drive.image ? -10 : 28}
        marginLeft="15%"
        marginRight="5%"
        bgColor="white"
        position={'relative'}
        zIndex={1}
        boxShadow="lg"
        textAlign={'left'}
        paddingX="10"
        paddingBottom="4"
      >
        <Box
          position={'absolute'}
          bgColor="white"
          minH={24}
          top={0}
          left={0}
          right={0}
          zIndex={-1}
          transform="skewY(-7deg)"
          transformOrigin="0 0"
        ></Box>

        <Box fontSize="xx-large" fontFamily="heading" pt="2" mb="2" color="#0039CD">
          {donation_drive.title}
        </Box>

        <Box dangerouslySetInnerHTML={{ __html: donation_drive.brief_description }} />

        <Box mt={6} display={'flex'} alignItems="center">
          {linkToDonatePage && (
            <Box mr={6}>
              <Link href={`/subscribe/0`}>
                <Button variant="solid" colorScheme="pink">
                  Donate Now
                </Button>
              </Link>
            </Box>
          )}
          <Box
            color="#0039CD"
            textDecoration="underline"
            as="a"
            href={donation_drive.more_info_link}
            target="_blank"
          >
            More info
          </Box>
        </Box>

        <SimpleGrid columns={{ base: 1, sm: 3 }} gap={2} mt="6">
          <Box>
            <Box fontSize="x-large">${Math.round(donation_drive.donated_amount / 100)}</Box>

            {donation_drive.target && (
              <span>of ${Math.round(donation_drive.target / 100).toLocaleString()} target</span>
            )}
            {!donation_drive.target && <span>total raised</span>}
          </Box>
          <Box>
            <Box fontSize="x-large">{donation_drive.supporter_count}</Box>
            supporters
          </Box>

          {donation_drive.minutes_left >= 1440 && (
            <Box>
              <Box fontSize="x-large">{Math.round(donation_drive.minutes_left / 1440)}</Box>
              {Math.round(donation_drive.minutes_left / 1440) === 1 ? 'day left' : 'days left'}
            </Box>
          )}
          {donation_drive.minutes_left >= 60 && donation_drive.minutes_left < 1440 && (
            <Box>
              <Box fontSize="x-large">{Math.round(donation_drive.minutes_left / 60)}</Box>
              {Math.round(donation_drive.minutes_left / 60) === 1 ? 'hour left' : 'hours left'}
            </Box>
          )}
          {donation_drive.minutes_left < 60 && (
            <Box>
              <Box fontSize="x-large">{donation_drive.minutes_left}</Box>
              {donation_drive.minutes_left === 1 ? 'minute left' : 'minutes left'}
            </Box>
          )}
        </SimpleGrid>

        {donation_drive.target && (
          <Box mt="4" minH="4" backgroundColor="#a9c1ff" position="relative">
            <Box
              height="4"
              backgroundColor="#0039CD"
              position="absolute"
              top="0"
              left="0"
              width={`${(donation_drive.donated_amount / donation_drive.target) * 100}%`}
            ></Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
